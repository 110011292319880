// Here you can add other styles

.logo{
  font-family: 'OpenDyslexicRegular';
  font-size: 28px;
  letter-spacing: 2px;
}

.c-sidebar-minimized .logo{
  font-size: 16px;
}

.actionButton{
  margin-left: 5px;
}

.export-btn{
  font-weight: bold;
  color: white;
}

.upload-new-book{
  font-weight: bold;
  color: white;
}

.card-header h4{
  margin-bottom: 0;
}

.custom-toaster{
  margin-top: 10px;
}
.custom-toast{
  border: none;
}
.custom-alert{
  margin: 0;
}
